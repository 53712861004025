<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "banner管理" }}</template>
            <template #toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <Button style="margin-right: 10px" type="info" @click="addBtn">新增</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!-- 新增修改界面 -->
        <LiefengModal :title="editType == 'add' ? '新增' : '修改'" :value="editStatus" @input="changeEditStatus" width="600px" height="450px">
            <template v-slot:contentarea>
                <Form :label-width="120">
                    <FormItem>
                        <span slot="label" class="validate">标题:</span>
                        <Input v-model.trim="formData.title" style="width: 300px"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">关联类型:</span>
                        <RadioGroup v-model="formData.bannerType">
                            <Radio label="-5">内容详情</Radio>
                            <Radio label="-1">内容列表</Radio>
                            <Radio label="-2">页面</Radio>
                            <Radio label="-3">第三方链接</Radio>
                            <Radio label="-4">小程序</Radio>
                        </RadioGroup>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">跳转链接:</span>
                        <Input v-model.trim="formData.bannerLink" style="width: 300px"></Input>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">排序:</span>
                        <InputNumber :min="1" :max="999999999" v-model="formData.sort"></InputNumber>
                    </FormItem>
                    <FormItem>
                        <span slot="label" class="validate">背景:</span>
                        <RadioGroup v-model="formData.bannerBg">
                            <Radio label="icon">图标</Radio>
                            <Radio label="image">背景</Radio>
                        </RadioGroup>
                        <LiefengUpload ref="imgData" v-if="showImage" accept=".jpg,.png,.gif,.jpeg" :format="['jpg', 'png', 'jpeg', 'gif']" :defaultList="imgData" :showView="true"></LiefengUpload>
                    </FormItem>
                </Form>
            </template>
            <template v-slot:toolsbar>
                <Button style="margin-right: 10px" type="primary" @click="save">保存</Button>
                <Button style="margin-right: 10px" type="info" @click="changeEditStatus(false)">关闭</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
// import AddFrom from "./components/addForm.vue"
export default {
    components: { LiefengContent, LiefengTable, LiefengModal, LiefengUpload },
    data() {
        return {
            searchData: { title: "" },
            // table表格数据
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,
            talbeColumns: [
                {
                    title: "标题",
                    minWidth: 400,
                    align: "center",
                    key: "title",
                },
                {
                    title: "类型",
                    minWidth: 200,
                    align: "center",
                    key: "bannerType",
                    render: (h, params) => {
                        return h(
                            "span",
                            {
                                style: {
                                    dispiay: "inline-block",
                                    backgroundColor: "#D3F4F4",
                                    padding: "20px 10px",
                                    color: "#24C8C8",
                                },
                            },
                            params.row.bannerType == "-5"
                                ? "内容详情"
                                : params.row.bannerType == "-1"
                                ? "内容列表"
                                : params.row.bannerType == "-2"
                                ? "页面"
                                : params.row.bannerType == "-3"
                                ? "第三方链接"
                                : params.row.bannerType == "0"
                                ? "信息"
                                : "小程序"
                        )
                    },
                },

                {
                    title: "链接",
                    minWidth: 400,
                    align: "center",
                    key: "bannerLink",
                },
                {
                    title: "排序",
                    minWidth: 100,
                    align: "center",
                    key: "sort",
                },
                {
                    title: "背景",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("Avatar", {
                                props: {
                                    src: params.row.images,
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "状态",
                    key: "status",
                    minWidth: 200,
                    align: "center",
                    render: (h, params) => {
                        return h("i-Switch", {
                            props: {
                                size: "large",
                                value: params.row.status == 1,
                            },
                            scopedSlots: {
                                open: () => h("span", "启用"),
                                close: () => h("span", "停用"),
                            },
                            on: {
                                "on-change": () => {
                                    // console.log(params.row);
                                    this.formData = {
                                        ...params.row,
                                        status: params.row.status == 1 ? 2 : 1,
                                    }
                                    delete this.formData._index
                                    delete this.formData._rowKey
                                    this.upData()
                                },
                            },
                        })
                    },
                    // render:(h,params) =>{
                    //   return h('div',{},params.row.status == 1 ?'正常':'禁用' )
                    // }
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    width: 250,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.edit(params.row.appBannerId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.delete(params.row.appBannerId)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ]
                        )
                    },
                },
            ],

            // 分享并预览模态框状态
            officialStatus: false,

            // 存放点击分享并预览的时候点击的当前的infoId
            qrCodeId: "",
            qrOCdeName: "", //信息标题
            codeString: "", // 传给组件的code

            // 新增修改参数
            formData: {
                appBannerId: "",
                appCode: "suiyue_weapp",
                title: "",
                images: "",
                bannerType: "-5",
                bannerLink: "",
                bannerBg: "icon",
                status: "1",
                sort: 0,
            },
            editType: "",
            showImage: true,
            imgData: [],
            editStatus: false,
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 方法部分
        changeEditStatus(val) {
            if (!val)
                (this.formData = {
                    bannerType: "-1",
                    photoType: "image",
                    sort: 1,
                }),
                    (this.imgData = [])
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
            this.editStatus = val
        },
        edit(id) {
            this.editType = "upData"
            this.$get("/gateway/lf-common/api/lfcommon/pc/appBanner/queryById", {
                appBannerId: id,
            }).then(res => {
                this.editStatus = true
                if (res.code == 200) {
                    this.showImage = false
                    this.$nextTick(() => {
                        this.showImage = true
                        let data = JSON.parse(JSON.stringify(res.data))
                        this.imgData = [{ name: "", url: data.images }]
                        this.formData = data
                    })
                }
            })
        },
        delete(appBannerId) {
            this.$Modal.confirm({
                title: "删除确定",
                content: "是否删除当前链接",
                onOk: () => {
                    this.$post("/gateway/lf-common/api/lfcommon/pc/appBanner/deleteById", {
                        appBannerId,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            this.getList()
                        } else {
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                            return
                        }
                    })
                },
            })
        },
        // 保存按钮
        save() {
            if (!this.formData.title) {
                this.$Message.warning({
                    content: "请输入标题",
                    background: true,
                })
                return
            }
            if (!this.formData.bannerType) {
                this.$Message.warning({
                    content: "请选择关联类型",
                    background: true,
                })
                return
            }
            if (!this.formData.bannerLink) {
                this.$Message.warning({
                    content: "请输入跳转链接",
                    background: true,
                })
                return
            }
            if (!this.formData.bannerBg) {
                this.$Message.warning({
                    content: "请选择背景类型",
                    background: true,
                })
                return
            }
            if (this.$refs.imgData.uploadList.length == 0) {
                this.$Message.warning({
                    content: "请添加图片",
                    background: true,
                })
                return
            } else {
                this.formData.images = this.$refs.imgData.uploadList[0].url
            }
            if (this.editType == "add") {
                this.add()
            } else {
                this.upData()
            }
        },
        add() {
            this.$post(
                "/gateway/lf-common/api/lfcommon/pc/appBanner/saveAppBanner",
                {
                    ...this.formData,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })
                    this.showImage = false
                    this.$nextTick(() => {
                        this.showImage = true
                    })
                    this.editStatus = false
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        upData() {
            this.$post(
                "/gateway/lf-common/api/lfcommon/pc/appBanner/saveAppBanner",
                {
                    ...this.formData,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "修改成功",
                        background: true,
                    })
                    this.showImage = false
                    this.$nextTick(() => {
                        this.showImage = true
                    })
                    this.editStatus = false
                    this.getList()
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },
        // 重置按钮
        resetBtn() {
            this.page = 1
            this.getList()
        },
        addBtn() {
            this.editType = "add"
            this.editStatus = true
            this.imgData = []
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })
            this.openStatusFn(true)
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gateway/lf-common/api/lfcommon/pc/appBanner/selectAppBannerPage", {
                appCode: "suiyue_weapp",
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                if (res && res.code && res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
</style>